import { IPaperCitations, IPaperReference } from '../../../types/paper.type';
import { createLink } from '../../../utils/helperFunctions';

/**
 * Generates a responsive HTML layout for "Cites" and "Cited By".
 *
 * - On large screens: Displays "Cites" and "Cited By" side by side in a table.
 * - On mobile screens: Stacks "Cites" and "Cited By" vertically in individual sections.
 *
 * @param {IPaperReference[]} references - Array of paper references.
 * @param {IPaperCitations[]} citations - Array of paper citations.
 * @returns {string} - Formatted responsive HTML string.
 */
export const generateCitesCitedByHTML = (
  references: IPaperReference[],
  citations: IPaperCitations[]
): string => {
  const maxLength = Math.max(references.length, citations.length);
  let tableRows = '';
  let mobileEntries = '';

  for (let i = 0; i < maxLength; i++) {
    const reference = references[i];
    const citation = citations[i];

    // Generate "Cites" cell content for table
    let citesCell = '';
    if (reference) {
      const link = createLink('/paper/' + reference.oipubPaperId);
      citesCell = `
        <td class="border px-4 py-2">
          <div>DOI: ${reference.cited}</div>
          <div>Title: ${
            reference.title
              ? `<a href="${link}" class="text-blue-500 underline hover:opacity-75">${reference.title}</a>`
              : 'The paper is absent in OiPub.'
          }</div>
        </td>
      `;
    } else {
      citesCell = `
        <td class="">
        </td>
      `;
    }

    // Generate "Cited By" cell content for table
    let citedByCell = '';
    if (citation) {
      const link = createLink(`/paper/${citation.oipubPaperId}`);
      citedByCell = `
        <td class="border px-4 py-2">
          <div>DOI: ${citation.citing}</div>
          <div>
            Title: ${
              citation.title
                ? `<a href="${link}" class="text-blue-500 underline hover:opacity-75">${citation.title}</a>`
                : 'The paper is absent in OiPub.'
            }
          </div>
        </td>
      `;
    } else {
      citedByCell = `
        <td class="">
        </td>
      `;
    }

    tableRows += `
      <tr>
        ${citesCell}
        ${citedByCell}
      </tr>
    `;

    // Generate content for mobile
    mobileEntries += `
    <div class="mb-6 border border-gray-300 p-4 rounded">
      ${
        reference && reference.cited
          ? `
        <div class="mb-4">
          <h3 class="font-semibold text-lg mb-2">Cites:</h3>
          <p><strong>DOI:</strong> ${reference.cited}</p>
          <p>
            <strong>Title:</strong> ${
              reference.title
                ? `<a href="${createLink('/paper/' + reference.oipubPaperId)}" class="text-blue-500 underline hover:opacity-75">${reference.title}</a>`
                : 'The paper is absent in OiPub.'
            }
          </p>
        </div>
      `
          : ''
      }
      ${
        citation && citation.citing
          ? `
        <div>
          <h3 class="font-semibold text-lg mb-2">Cited By:</h3>
          <p><strong>DOI:</strong> ${citation.citing}</p>
          <p>
            <strong>Title:</strong> ${
              citation.title
                ? `<a href="${createLink('/paper/' + citation.oipubPaperId)}" class="text-blue-500 underline hover:opacity-75">${citation.title}</a>`
                : 'The paper is absent in OiPub.'
            }
          </p>
        </div>
      `
          : ''
      }
    </div>
  `;
  }

  return `
    <div class="cites-citedby-container mt-6">
      <div class="cititaion__desktop lg:block overflow-auto">
        <table class="min-w-full border-collapse">
          <thead>
            <tr>
              <th class="border px-4 py-2 text-left w-1/2">Cites</th>
              <th class="border px-4 py-2 text-left w-1/2">Cited By</th>
            </tr>
          </thead>
          <tbody>
            ${tableRows}
          </tbody>
        </table>
      </div>
      <div class="block cititaion__mobile">
        ${mobileEntries}
      </div>
    </div>
  `;
};
