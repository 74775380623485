import { Furniturev2 } from './paper-furniture';
import { RenderHTML } from '../../components/paper/render-html';
import { useAppSelector } from '../../state';
import {
  selectComments,
  selectCommentsLoading
} from '../../state/selectors/commentsSelector';
import { Loading } from '../../components/core';
import { useCommentsPaper } from '../../components/discussion/hooks/useCommentsPaper';
import { Discussion } from '../../components/discussion';
import { texts } from '../../text';
import { AccordionItem } from '../../components/accordion';
import { useEffect, useState } from 'react';
import { generateCitesCitedByHTML } from './utils/generateCitesCitedByHTML';
import { AccordionItemSticky } from '../../components/accordion/accordionItemSticky';

export const PaperPageContainer: React.FC = () => {
  const { handleReply, handleDelete, handleEdit } = useCommentsPaper();

  const paper = useAppSelector((state) => state.paper.paper);
  const comments = useAppSelector(selectComments);
  const loading = useAppSelector(selectCommentsLoading);
  const [isExpandedAbstract, setIsExpandedAbstract] = useState<boolean>(false);
  const [isExpandedCitations, setIsExpandedCitations] =
    useState<boolean>(false);
  const [disabledCitations, setDisabledCitations] = useState<boolean>(false);

  useEffect(() => {
    if (!paper?.abstract) {
      setIsExpandedAbstract(false);
    } else {
      setIsExpandedAbstract(true);
    }
  }, [paper]);

  const handleToggleAbstract = () => {
    setIsExpandedAbstract((prev: any) => !prev);
  };

  useEffect(() => {
    if (
      !paper?.references ||
      paper.references.length === 0 ||
      !paper?.citations ||
      paper.citations.length === 0
    ) {
      setDisabledCitations(true);
      setIsExpandedCitations(false);
    } else {
      setDisabledCitations(false);
    }
  }, [paper]);

  const handleToggleCitations = () => {
    if (!disabledCitations) {
      setIsExpandedCitations((prev) => !prev);
    }
  };

  const combinedHTML =
    paper?.references &&
    paper.references.length > 0 &&
    paper?.citations &&
    paper.citations.length > 0
      ? generateCitesCitedByHTML(paper.references, paper.citations)
      : '';

  const accordionContent =
    paper?.references &&
    paper.references.length > 0 &&
    paper?.citations &&
    paper.citations.length > 0
      ? combinedHTML
      : texts.papersWithoutAbstract;

  return (
    <div className="">
      <div className="paperBorder"></div>
      <main className="pt-8 pb-6 tablet:px-12 px-6">
        {paper && paper.title && paper.id && (
          <Furniturev2 title={paper.title} id={paper.id} />
        )}
        <div className="withStyles block mt-6">
          <div className=" tablet:order-first order-last page-content accordionPapers">
            <div className="mb-10">
              <AccordionItem
                title="Abstract"
                isExpanded={isExpandedAbstract}
                onToggle={handleToggleAbstract}
              >
                {paper?.abstract ? (
                  <RenderHTML htmlContent={paper.abstract} />
                ) : (
                  <RenderHTML
                    htmlContent={texts.papersWithoutAbstract}
                    className="text-gray-600"
                  />
                )}
              </AccordionItem>
            </div>
          </div>
          <div className="mb-0  sticky-accordion-container accordionPapers ">
            <AccordionItemSticky
              title="Cites & Cited By"
              isExpanded={isExpandedCitations}
              onToggle={handleToggleCitations}
              disabled={disabledCitations}
              tooltipText={
                disabledCitations
                  ? 'Unfortunately we don’t have information about the paper citations.'
                  : undefined
              }
            >
              <RenderHTML
                htmlContent={accordionContent}
                className={disabledCitations ? 'text-gray-600' : ''}
              />
            </AccordionItemSticky>
          </div>
        </div>
      </main>
      {loading ? (
        <Loading />
      ) : (
        <Discussion
          type="paper"
          comments={comments}
          onReply={handleReply}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};
