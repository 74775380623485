import React, { useState, useRef, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import { DiscussionType } from './discussion-type';
import { IComment } from '../../types/comment.type';
import { useAppDispatch, useAppSelector } from '../../state';
import { analytics } from '../../analytics';
import { Button } from '../form';
import { Pencil } from '../../svgs';
import { CountCommentsOrder } from './count-comments-order';
import { RichTextEditor } from '../richTextEditor';
import { Comments } from './comments';
import {
  closeAllComments,
  setNewCommentFormVisibility
} from '../../state/slices/commentsReducer';
import { useNavigate } from 'react-router-dom';

export interface Props {
  type: DiscussionType;
  comments: IComment[] | null;
  onReply(text: string, parentId: number | null): Promise<void>;
  onEdit(commentId: number, body: string): Promise<void>;
  onDelete(commentId: number): Promise<void>;
}

const CommentSchema = Yup.object().shape({
  commentText: Yup.string()
    .required('Required')
    .min(1, 'Comment must be at least 1 character long')
});

export const Discussion: React.FC<Props> = ({
  type,
  comments,
  onReply,
  onEdit,
  onDelete
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [text, setText] = useState('');
  const user = useAppSelector((s) => s.login.user);
  const [saving, setSaving] = useState(false);
  const newCommentShow = useAppSelector((s) => s.comments.newCommentFormShow);

  const quillRef = useRef<ReactQuill | null>(null);

  const handleAddClick = () => {
    dispatch(setNewCommentFormVisibility(true));
    dispatch(closeAllComments());
    setTimeout(() => {
      const editor = quillRef.current?.getEditor();
      if (editor) {
        editor.focus();
        const textLength = text?.length || 0;
        editor.setSelection(textLength, textLength);
      }
    }, 0);
  };

  const handleCancelClick = () => {
    dispatch(setNewCommentFormVisibility(false));
    setText('');
  };

  const formik = useFormik({
    initialValues: {
      commentText: text
    },
    validationSchema: CommentSchema,
    onSubmit: async () => {
      await handleReply();
    },
    validateOnChange: true,
    validateOnBlur: true
  });

  const handleOpenModalHash = useCallback(
    (modalName: string) => {
      const currentSearch = location.search;
      navigate(`${currentSearch}#${modalName}`, { replace: true });
    },
    [navigate]
  );

  const openSignupModal = useCallback(
    () => handleOpenModalHash('signup'),
    [handleOpenModalHash]
  );

  const handleCommentChange = useCallback(
    (value: string) => {
      formik.setFieldValue(
        'commentText',
        new DOMParser()
          .parseFromString(value, 'text/html')
          ?.body.textContent?.trim() ?? ''
      );
      setText(value);
    },
    [formik, setText]
  );

  const handleReply = async () => {
    setSaving(true);
    try {
      analytics.events.addComment(type);
      await onReply(text, null);
      setText('');
      dispatch(setNewCommentFormVisibility(false));
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="tablet:px-12 px-6 top-bar-border-top">
      <div className="tablet:mt-6 mt-3">
        <div
          className={`flex items-start justify-between ${!newCommentShow ? 'mb-4' : 'mb-2'} `}
        >
          {user ? (
            !newCommentShow ? (
              <div className="flex justify-between w-full laptop:items-center items-start laptop:flex-row flex-col">
                <Button
                  outline={false}
                  onClick={handleAddClick}
                  className="py-2 px-6 mb-2 laptop:mb-0"
                >
                  <span className="inline-block w-4 h-4 mr-1">
                    <Pencil />
                  </span>
                  <span>Add comment</span>
                </Button>
                {comments && comments.length > 0 && (
                  <CountCommentsOrder comments={comments} />
                )}
              </div>
            ) : (
              ''
            )
          ) : (
            <Button outline={true} onClick={openSignupModal}>
              <span className="inline-block w-4 h-4 mr-1">
                <Pencil />
              </span>
              <span>Sign up to join the discussion</span>
            </Button>
          )}
        </div>
        {newCommentShow && (
          <form
            className="w-full mx-auto filters"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className="mb-4">
              <h4 className="text-primary-700 relative z-20 mb-2 mr-10 text-sm font-bold tracking-wide uppercase">
                New Comment
              </h4>
              <div className="text-sm mb-4 commentEditor">
                <RichTextEditor
                  value={text}
                  setValue={handleCommentChange}
                  maxLength={10000}
                  disabled={saving}
                  error={
                    formik.touched.commentText && formik.errors.commentText
                      ? formik.errors.commentText
                      : undefined
                  }
                  touched={formik.touched.commentText}
                  placeholder="Type your comment here"
                  ref={quillRef}
                />
              </div>
              <div className="flex justify-between items-start laptop:flex-row flex-col">
                <div className="flex mb-1">
                  <Button
                    outline={true}
                    disabled={saving}
                    onClick={handleCancelClick}
                    className="py-2 tablet:px-12 px-6"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={saving}
                    type="submit"
                    className="py-2 tablet:px-12 px-6 ml-2"
                  >
                    Submit
                  </Button>
                </div>
                <CountCommentsOrder comments={comments} />
              </div>
            </div>
          </form>
        )}

        {comments === null || comments.length === 0 ? (
          <p className="text-sm text-gray-600 mb-4">
            The discussion has not yet been started.
          </p>
        ) : (
          <div className="px-4 bg-white">
            <div className="px-8 py-6 pb-1">
              <Comments
                comments={comments}
                type={type}
                onReply={onReply}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
